import { getPlaiceholder } from 'plaiceholder';
import HomeContainer from '@app/containers/HomeContainer';

export async function getStaticProps() {
  const { CDN_URL } = process.env;

  const imgArray = [
    `/images/homepageHeadingImage.png`,
    `/images/phoneMarketplace.png`,
    `/images/logoShapedHouse.png`,
  ];

  const images = await Promise.all(
    imgArray.map(async (src) => {
      const {
        base64,
        img: { width, height, ...img },
      } = await getPlaiceholder(`${CDN_URL?.replace(/^\/|\/$/g, '')}${src}`);
      return {
        ...img,
        src,
        alt: `img-${src}`,
        blurDataURL: base64,
      };
    }),
  ).then((values) => values);

  return {
    props: {
      images,
    },
  };
}

export default HomeContainer;
